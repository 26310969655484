:root {
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
  --ease-out-smooth: cubic-bezier(0.19, 1, 0.22, 1);

  --duration-150: 150ms;
  --duration-300: 300ms;
  --duration-600: 600ms;
  --duration-1500: 1500ms;

  --duration-panel-animation: var(--duration-1500);
}
