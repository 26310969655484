.component {

  --padding-inline: var(--size-35);
  --padding-block: var(--size-10);

  padding: var(--size-10);
  animation: slideInDown var(--duration-panel-animation) var(--ease-out-smooth) forwards;
  pointer-events: none;

  @media (--viewport-md) {
    animation: slideInSide var(--duration-panel-animation) var(--ease-out-smooth) forwards;
  }

  & > .layout {
    margin-left: auto;
    max-width: 450px;
  }

  /* TODO: refactor to new component  */
  .buttonLayout {
    font-size: var(--font-size-16-18);
    padding: min(4vw, var(--size-18)) min(6vw, var(--size-30));
  }
}

.layout {
  padding: var(--size-30) var(--size-35);
  background-color: var(--color-purple);
  color: var(--color-beige);
  border-radius: var(--border-radius-50);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-20);
  pointer-events: visible;

  & > .cookieTogglesWrapper {
    min-width: 100%;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-10);
  justify-content: center;

  & > * {
    width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}

.settings {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (--viewport-md) {
    justify-content: flex-end;
  }
}

.heading {
  font: var(--font-headingMd);
}

.intro {
  font-weight: var(--font-weight-base);
  font-size: var(--font-size-12-14);
}

.link {
  text-decoration: underline;
  color: inherit;
}

@keyframes slideInDown {
  from {
    translate: 0% calc(100% + var(--padding-inline));
  }

  to {
    translate: 0 0;
  }
}

@keyframes slideInSide {
  from {
    translate: calc(100% + var(--padding-block)) 0%;
  }

  to {
    translate: 0 0;
  }
}
